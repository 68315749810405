import axios from 'axios';
import setNewFormData from 'helpers/setNewFormData';
import { useEffect, useState } from 'react';
import handleDraft from '../functions/handleDraft';
import { showToast } from 'module/Common/Toast/toast';
import removeRefData from 'helpers/removeRefData';
import { autoFocusInput } from 'module/Sales/common/functions/autoFocusInput';

function useDraft(draftDependencies) {
  const [formData, setFormData] = useState(
    draftDependencies && draftDependencies.defaultFormData
      ? draftDependencies.defaultFormData
      : null
  );
  const [netAmounts, setNetAmounts] = useState({
    GrossTotal: 0,
    ItemTotal: 0,
    GLTotal: 0,
    discount: 0,
    tax: 0
  });
  const [draftReadyToSave, setDraftReadyToSave] = useState(false);
  const [draftId, setDraftId] = useState(null);
  const [draftData, setDraftData] = useState({});
  const [lastSavedDraftData, setLastSavedDraftData] = useState('');
  const [draftIsSaving, setDraftIsSaving] = useState(false);
  const [showDraftWindow, setShowDraftWindow] = useState(false);
  const [timer, setTimer] = useState(null);

  const saveDraftData = (data, draftId) => {
    const draftableData = typeof data === 'object' ? { ...data } : undefined;

    delete draftableData.files;
    let encodedData = encodeURIComponent(JSON.stringify(draftableData));

    if (lastSavedDraftData === encodedData) return;

    if (!draftReadyToSave) {
      setLastSavedDraftData(encodedData);
      return;
    }

    if (draftIsSaving) return;
    clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      setDraftIsSaving(true);
      await handleDraft({
        trans_type: draftDependencies ? draftDependencies.trans_type : null,
        data: draftableData,
        draftId: draftId,
        setDraftId: setDraftId,
        setDraftData: setDraftData,
        setShowDraftWindow: setShowDraftWindow,
        withoutItemsArray:
          draftDependencies && draftDependencies.withoutItemsArray
            ? draftDependencies.withoutItemsArray
            : [],
        conversionData:
          draftDependencies && draftDependencies.conversionData
            ? draftDependencies.conversionData
            : {}
      });
      setLastSavedDraftData(encodedData);
      setDraftIsSaving(false);
    }, 1300);
    setTimer(newTimer);
  };

  const getDraftData = async () =>
    handleDraft({
      trans_type: draftDependencies ? draftDependencies.trans_type : null,
      setDraftId: setDraftId,
      setDraftData: setDraftData,
      setShowDraftWindow: setShowDraftWindow,
      conversionData:
        draftDependencies && draftDependencies.conversionData
          ? draftDependencies.conversionData
          : {}
    });

  const handleResumeDraftData = () => {
    setFormData(prev => ({ ...prev, ...draftData.data.draft_data }));
  };

  useEffect(() => {
    if (!draftIsSaving) {
      saveDraftData(formData, draftId);
    }
  }, [formData, draftId, draftIsSaving]);

  useEffect(() => {
    let netTotalAndDiscount = {
      GrossTotal: 0,
      ItemTotal: 0,
      GLTotal: 0,
      discount: 0,
      tax: 0
    };
    if (formData?.details && Array.isArray(formData.details)) {
      netTotalAndDiscount = formData.details.reduce(
        (initialValue, item) => {
          let subTotal = parseFloat(item?.sub_total ?? 0);
          let netTotal = parseFloat(item?.line_total ?? 0);
          let netDiscount = parseFloat(item?.net_discount_amount ?? 0);
          let netTax = parseFloat(item?.line_total_tax ?? 0);

          initialValue.GrossTotal += subTotal;
          initialValue.ItemTotal += netTotal;
          initialValue.discount += netDiscount;
          initialValue.tax += netTax;
          return initialValue;
        },
        { GrossTotal: 0, ItemTotal: 0, discount: 0, tax: 0 }
      );
    }

    if (formData?.gl_items && Array.isArray(formData.gl_items)) {
      let glTotalData = formData.gl_items.reduce(
        (initialValue, item) => {
          initialValue.tax += parseFloat(
            item?.total_ref?.total_tax_amount ?? 0
          );
          initialValue.GLTotal += parseFloat(item?.total_ref?.total ?? 0);
          initialValue.GrossTotal += parseFloat(item?.amount ?? 0);
          return initialValue;
        },
        { GrossTotal: 0, GLTotal: 0, tax: 0 }
      );

      netTotalAndDiscount.GLTotal = glTotalData.GLTotal;
      netTotalAndDiscount.GrossTotal += glTotalData.GrossTotal;
      netTotalAndDiscount.tax += glTotalData.tax;
    }

    setNetAmounts(netTotalAndDiscount);
  }, [JSON.stringify(formData?.details), JSON.stringify(formData?.gl_items)]);

  return {
    formData: formData,
    setFormData: setFormData,
    netAmounts: netAmounts,
    draftId: draftId,
    setDraftId: setDraftId,
    draftData: draftData,
    setDraftData: setDraftData,
    draftIsSaving: draftIsSaving,
    setDraftIsSaving: setDraftIsSaving,
    showDraftWindow: showDraftWindow,
    setShowDraftWindow: setShowDraftWindow,
    saveDraftData: () => setDraftReadyToSave(true),
    disableSaveDraftData: () => setDraftReadyToSave(false),
    getDraftData: getDraftData,
    handleResumeDraftData: handleResumeDraftData
  };
}

function useSaveToCart({
  onTableItemChange,
  cartItems,
  transType,
  transNumber
}) {
  const [isSaving, setIsSaving] = useState(false);

  const saveToCart = itemsArray => {
    setIsSaving(true);
    let arrayOfCartItems = setNewFormData({
      items: itemsArray,
      trans_type: transType,
      ...(transNumber ? { trans_no: transNumber } : null)
    });

    axios
      .post('purchase/add-to-cart-bulk', arrayOfCartItems)
      .then(resposne => {
        if (resposne?.data?.data?.items) {
          let arrayOfCartItems = resposne.data.data.items.reduce(
            (intiArray, item) => {
              let id_ref = parseInt(item.id_ref);
              let itemRef = cartItems.find(
                cartItem => cartItem.id_ref === id_ref
              );

              if (!item.id) {
                delete item.id;
              }

              item.add_to_draft_ref = itemRef.add_to_draft_ref ?? true;
              item.id_ref = id_ref;
              item.removeThisKeys = [
                'edit',
                'add_to_cart_ref',
                'add_to_draft_ref'
              ];
              intiArray.push(item);
              return intiArray;
            },
            []
          );

          if (arrayOfCartItems.length > 0) {
            onTableItemChange(
              arrayOfCartItems,
              'UPDATE-MULTIPLE',
              arrayOfCartItems.filter(
                cartItem => cartItem.add_to_draft_ref === true
              ).length > 0
                ? true
                : false
            );
          }

          setIsSaving(false);
        }
        autoFocusInput('stock_id');
      })
      .catch(error => {
        if (
          error?.response?.data?.data?.errors &&
          typeof error.response.data.data.errors === 'object'
        ) {
          let allErrors = error.response.data.data.errors;
          let newData = cartItems.reduce((intiArray, item) => {
            let errors = allErrors[item.id_ref] ?? null;
            if (errors) {
              item.errors = errors;
              item.removeThisKeys = ['add_to_cart_ref'];
            }
            intiArray.push(item);
            return intiArray;
          }, []);

          if (newData.length > 0) {
            onTableItemChange(newData, 'UPDATE-MULTIPLE');
          }

          setIsSaving(false);
        }
      });
  };

  useEffect(() => {
    if (!isSaving && Array.isArray(cartItems)) {
      let pendingCartItems = cartItems.reduce((array, item) => {
        if (array.length < 10 && item.add_to_cart_ref === true) {
          let cartItem = removeRefData(item);
          cartItem.id_ref = item.id_ref;
          cartItem.tax_included = item.tax_included ? 1 : 0;

          if (item?.tax_group_id) {
            cartItem.tax_group_id = item.tax_group_id;
          }

          if (item.batch_number) {
            cartItem.batch_number = item.batch_number;
          }

          if (parseInt(item.is_kit) === 1) {
            if (item.kit_items) {
              let kit_items = {};
              setNewFormData({
                ['kit_items]']: item.kit_items.reduce((newArray, item) => {
                  newArray.push({
                    tax_included: cartItem.tax_included,
                    id: item.id,
                    kit_item_id: item.kit_item_id,
                    kit_id: item.kit_id,
                    stock_id: item.stock_id,
                    base_quantity: item.base_quantity_ref
                  });
                  return newArray;
                }, [])
              }).forEach((value, key) => (kit_items[key] = value));
              cartItem = { ...cartItem, ...kit_items };
            }
            cartItem.kit_id = cartItem.stock_id;
            delete cartItem.stock_id;
          }

          if (item?.discount_unit_ref === '%') {
            cartItem.discount_percent = item.discount_percent;
          } else if (item?.discount_amount > 0) {
            cartItem.discount_amount = item.discount_amount;
          }

          array.push(cartItem);
        }
        return array;
      }, []);

      if (pendingCartItems.length > 0) {
        if (!pendingCartItems[0].trans_date) {
          return showToast(
            'The transaction date is invalid. Please select a valid date.',
            'error'
          );
        }
        saveToCart(pendingCartItems);
      }
    }
  }, [isSaving, cartItems]);

  return {
    saveToCart: saveToCart,
    isSaving: isSaving
  };
}

export { useDraft, useSaveToCart };
