import useAxisproPermission from 'hooks/useAxisproPermission';

const permissionActions = {
  create: 'create',
  store: 'store',
  issue: 'issue',
  show: 'show',
  update: 'update',
  view: 'view',
  revise: 'revise',
  delete: 'delete',
  print: 'print',
  'send-mail': 'send-mail',
  vouchers: 'vouchers',
  'show-all': 'show-all',
  export: 'export',
  view_created: 'view-created',
  update_created: 'update-created',
  switch: 'switch',
  TRANSACTION: 'transaction',
  save: 'save',
  dynamic: 'dynamic',
  MR: 'mr'
};
const permissionType = {
  CN: 'credit-note',
  SI: 'si',
  SQ: 'sq',
  SD: 'sd',
  SO: 'so',
  PI: 'pi',
  PR: 'pr',
  PO: 'po',
  PD: 'pd',
  CR: 'cr',
  CT: 'ct',
  JV: 'jv',
  PV: 'pv',
  RV: 'rv',
  BT: 'bt',
  bt: 'bt',
  SP: 'sp',
  WO: 'wo',
  IA: 'ia',
  AS: 'approval-stage',
  ASS: 'approval-stages',
  ASTRANS: 'approval-stages-by-trans-types',
  CS: 'customer',
  CSG: 'customer-group',
  LI: 'list',
  LG: 'activities',
  SM: 'salesman',
  IT: 'item',
  DN: 'dn',
  RC: 'rental-contract',
  CRA: 'customer-alloc',
  supplier: 'supplier',
  BCH: 'batch-number',
  company: 'company',
  CSS: 'finance-setting',
  TL: 'transaction-lock',
  price: 'price',
  AT: 'accrual-transaction',
  bank: 'bank',
  CC: 'cost-center',
  RP: 'recurrent-profile',
  BRAND: 'brand',
  CG: 'category',
  SCG: 'sub-category',
  ITS: 'items',
  manufacturer: 'manufacturer',
  SK: 'stock-kit',
  ST: 'stock-transfer',
  UP: 'user-permission',
  user: 'user',
  WH: 'warehouse',
  unit: 'unit',
  TC: 'tc',
  TGI: 'tax-group-item',
  TG: 'tax-group',
  tax: 'tax',
  SC: 'ship-com',
  SST: 'sales-type',
  SA: 'sales-area',
  role: 'role',
  PT: 'payment-term',
  FY: 'fy',
  ER: 'exchange-rate',
  chat: 'chat',
  CF: 'custom-field',
  CREDITS: 'credit-status',
  CSQ: 'sq-only',
  CSO: 'so-only',
  CSD: 'sd-only',
  CSI: 'si-only',
  CCN: 'cn-only',
  ENQUIRY: 'enquiry',
  EQ: 'enquiry',
  ESTIMATION: 'estimation',
  BRANCH: 'branch',
  TRANSACTION: 'transaction',
  VOID: 'void',
  MR: 'mr',
  PI_ADDITIONAL_COST: 'additional-cost',
  FA: 'fixed-asset',
  F_EA: 'fixed-asset',
  PROPOSAL: 'proposal',
  CHART_OF_ACCOUNT: 'coa',
  BRC: 'brc',
  CCG: 'cost-center-group',
  RCT: 'rental-contract',
  ISSUE_ITEMS: 'issue-items'
};

export const getAcodaxPermissionSlug = (type, action) => {
  const axisProPermission = useAxisproPermission();
  const formattedAction = permissionActions[action] || 'unknown-action';
  const formattedType = permissionType[type] || 'unknown-type';
  return axisProPermission(`${formattedAction}-${formattedType}`);
};

// export const getAcodaxModulePermission = (type, action) => {
//   const axisProModulePermission = useAxisPropModulePermission();
//   const formattedAction = permissionActions[action] || 'unknown-action';
//   const formattedType = permissionType[type] || 'unknown-type';
//   return axisProModulePermission(`${formattedType}-${formattedAction}`);
// };
