import { React, useState } from 'react';
import { Dropdown, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaCaretDown } from 'react-icons/fa';

import SearchAndSelect from '../FinanceAccounts/SettingsFiles/SearchAndSelect';
import SelectBankAccount from 'components/form/SelectBankAccount';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';

function CustomFieldsNew({
  data,
  name,
  handleFieldChange,
  value,
  select,
  options,
  input,
  bank,
  groupInput,
  dropDownData,
  handleDropdownData,
  inputType,
  integer
}) {
  const Translate = useAxisproTranslate();
  const [dropDownValue, setDropDownValue] = useState(dropDownData);

  const handleData = data => {
    setDropDownValue(data);
    handleDropdownData(data);
  };
  const onChangeInput = (e, inputType, integer = false) => {
    const numberValue = e?.target?.value;
    if (inputType === 'number') {
      const validationConstant = integer ? /^[0-9]+$/ : /^[0-9\b.]+$/;
      let validation = validationConstant.test(e.target.value);
      if (
        numberValue &&
        (!validation || numberValue < 0 || isNaN(numberValue))
      ) {
        const errorMessage = integer
          ? 'Value must be a non-negative integer'
          : 'Value must be zero or above';
        return showToast(errorMessage, 'error');
      } else {
        handleFieldChange(e);
      }
    } else {
      handleFieldChange(e);
    }
  };

  return (
    <Form.Group as={Row} className="w-100">
      {options && options.length > 0 ? (
        <Form.Select name={name} onChange={handleFieldChange} value={value}>
          {options &&
            options.length > 0 &&
            options.map(item => (
              <option key={item.id} value={item.id}>
                {item.text}
              </option>
            ))}
        </Form.Select>
      ) : input ? (
        <Form.Control
          type={inputType ?? 'text'}
          style={{ width: '200px' }}
          className="ms-3 me-3 "
          value={value}
          name={name}
          onChange={e => onChangeInput(e, inputType, integer)}
        />
      ) : bank ? (
        <SelectBankAccount
          name={name}
          value={value}
          handleFieldChange={handleFieldChange}
        />
      ) : groupInput ? (
        <Form.Group className="d-flex" style={{ width: '250px' }}>
          <Form.Control
            className="ms-3"
            name={name}
            onChange={handleFieldChange}
            value={value}
          />
          <Dropdown data-bs-theme="dark">
            <Dropdown.Toggle
              id="dropdown-button-dark-example1"
              variant="transparent"
              size="sm"
              className="h-100 ps-2"
              style={{
                fontSize: '10px',
                border: '1px solid rgb(180,181,180)',
                borderLeft: 'none',
                boxShadow: 'none'
              }}
            >
              {Translate(dropDownValue.toUpperCase())} <FaCaretDown />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => handleData('auto')}
                active={dropDownValue === 'auto' ? true : false}
              >
                {Translate('AUTO')}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleData('up')}
                active={dropDownValue === 'up' ? true : false}
              >
                {Translate('UP')}
              </Dropdown.Item>
              <Dropdown.Item
                active={dropDownValue === 'down' ? true : false}
                onClick={() => handleData('down')}
              >
                {Translate('DOWN')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
      ) : (
        <SearchAndSelect
          data={data}
          name={name}
          handleFieldChange={handleFieldChange}
          value={value}
          select={select}
          settings={true}
        />
      )}
    </Form.Group>
  );
}

CustomFieldsNew.propTypes = {
  label: PropTypes.string,
  data: PropTypes.any,
  name: PropTypes.string,
  handleFieldChange: PropTypes.func,
  value: PropTypes.any,
  select: PropTypes.any,
  options: PropTypes.array,
  input: PropTypes.bool,
  bank: PropTypes.any,
  dropDownData: PropTypes.any,
  groupInput: PropTypes.any,
  handleDropdownData: PropTypes.func,
  inputType: PropTypes.string,
  integer: PropTypes.bool
};

export default CustomFieldsNew;
