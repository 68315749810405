import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Placeholder,
  ProgressBar,
  Spinner
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaFileUpload, FaSearch, FaTimes } from 'react-icons/fa';
import FormLayer from 'module/Common/Layer/FormLayer';
import axios from 'axios';
import setNewFormData from 'helpers/setNewFormData';
import {
  FaFilePdf,
  FaRegSquare,
  FaRegSquareCheck,
  FaTrash
} from 'react-icons/fa6';
import Search from 'components/search/Search';
import DataFetching from 'module/Report/LedgerReport/DataFetching';
import { DeleteContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';

export default function ProposalPageSelectionModal({
  setPages,
  activePlace,
  show,
  onHide
}) {
  const Translate = useAxisproTranslate();
  const { dispatch: configConfirm } = useContext(DeleteContext);
  const fileInputRef = useRef(null);
  const [uploadingFileData, setUploadingFileData] = useState(undefined);
  const [fetching, setFetching] = useState(false);
  const [pagesList, setPagesList] = useState([]);
  const [showSelection, setShowSelection] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searching, setSearching] = useState(false);
  const apiRequestAbortControllerRef = useRef(null);
  const pagesListCount = pagesList?.data?.length;
  const nextPage = (pagesList?.meta?.current_page || 0) + 1;
  const lastPage = pagesList?.meta?.last_page || 0;

  const selectedFiles = pagesList?.data?.filter(page => page?.selected);

  const handleSwitchState = (event, key) => {
    event?.preventDefault;
    if (key === 'selection') {
      setShowSelection(!showSelection);
    } else {
      setShowSearch(!showSearch);
    }
  };

  const handleFileInputClick = event => {
    event?.preventDefault();
    if (uploadingFileData) return;
    const fileInput = fileInputRef.current;
    if (!fileInput) return;
    fileInput.click();
  };

  const handleFiles = async (event, overwrite) => {
    try {
      const pdfFile = event?.target?.files?.[0];
      if (!pdfFile) return;

      const fileData = {
        file: pdfFile,
        overwrite: overwrite || '0'
      };

      setUploadingFileData({ ...fileData, now: 0 });

      // const newFormData =
      let newFormData = setNewFormData(fileData);
      const apiResult = await axios.postForm(
        'proposals-media-files',
        newFormData,
        {
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.round((loaded * 100) / total);
            setUploadingFileData(prev => ({ ...prev, now: percentCompleted }));
          }
        }
      );

      const uploadedFileData = apiResult?.data?.data;
      if (uploadedFileData?.id) {
        setPagesList(prev => ({
          ...prev,
          data: overwrite
            ? prev?.data?.map(page =>
                page?.id === uploadedFileData.id ? uploadedFileData : page
              )
            : [uploadedFileData, ...(prev?.data || [])]
        }));
      }
    } catch (error) {
      const errorData = error?.response?.data;
      if (errorData?.data?.already_exist) {
        handleFileOverwrite(event, errorData);
        return;
      }

      showToast(
        errorData?.message ||
          error?.message ||
          'Something went wrong, please refresh the page and try again.',
        'error'
      );
    } finally {
      setUploadingFileData(false);
    }
  };

  const handleFileOverwrite = (fileUploadEvent, error) => {
    const configConfirmPayload = {
      show: true,
      title: Translate('Selected file already exists!'),
      message: Translate(error?.message),
      submitButtonVariant: 'warning',
      buttonText: Translate('Overwrite'),
      cancelButtonVariant: 'secondary',
      onSubmit: event => {
        event?.preventDefault();
        handleFiles(fileUploadEvent, 1);
        configConfirm({
          type: 'RESET'
        });
      }
    };
    configConfirm({
      type: 'CONFIG',
      payload: configConfirmPayload
    });
  };

  const handleLineItemClick = (event, pageData) => {
    event?.preventDefault();
    const target = event?.currentTarget;
    if (!target) return;
    if (showSelection) {
      setPagesList(prev => ({
        ...prev,
        data: prev?.data?.map(page => {
          if (page?.id === pageData?.id) {
            return { ...page, selected: !page?.selected };
          }
          return page;
        })
      }));

      return;
    }

    handleOptionsButton(event, {
      targetaction: 'singleInclude',
      pageData: pageData
    });
  };

  const handleOptionsButton = (event, actionData) => {
    event?.preventDefault();
    const target = event?.currentTarget;
    const targetAction =
      actionData?.targetaction || target?.getAttribute('target-action');
    const targetId = actionData?.targetid || target?.getAttribute('target-id');

    if (
      !['delete', 'singleDelete', 'include', 'singleInclude'].includes(
        targetAction
      ) ||
      (targetAction === 'singleDelete' && !targetId) ||
      (targetAction === 'singleInclude' && !actionData?.pageData)
    )
      return;

    const actionKey = ['delete', 'singleDelete'].includes(targetAction)
      ? 'Delete'
      : 'Include';

    const action = actionKey?.toLocaleLowerCase();

    const isMultiple = !!(
      !['singleDelete', 'singleInclude'].includes(targetAction) &&
      selectedFiles?.length > 1
    );

    const configConfirmPayload = {
      show: true,
      title: Translate(`${actionKey} selected item${isMultiple ? 's' : ''}`),
      message: Translate(
        `Are you sure you want to ${actionKey.toLocaleLowerCase()} the selected item${
          isMultiple ? 's' : ''
        }?`
      ),
      submitButtonVariant: action === 'delete' ? 'danger' : 'success',
      buttonText: Translate(actionKey),
      cancelButtonVariant: action === 'delete' ? 'secondary' : 'warning',
      onSubmit: async (event, options) => {
        event?.preventDefault();
        const setLoading = status => {
          if (typeof options?.setDeleting === 'function') {
            options?.setDeleting(status);
          }
        };

        try {
          setLoading(true);
          if (action === 'include') {
            handleIncludePage(
              actionData?.pageData ? [actionData.pageData] : selectedFiles
            );
            if (typeof onHide === 'function') onHide();
            return;
          }

          configConfirm({
            type: 'CONFIG',
            payload: {
              ...configConfirmPayload,
              buttonText: (
                <div className="d-flex flex-row align-items-center gap-2">
                  <span>{Translate('Deleting')}</span>
                  <Spinner
                    animation="border"
                    style={{ width: 15, height: 15 }}
                  />
                </div>
              )
            }
          });

          const deletingItemsArray =
            targetAction === 'singleDelete'
              ? [targetId?.toString()]
              : selectedFiles?.map(page => page?.id?.toString());
          const apiResponse = await axios.delete('proposals-media-files', {
            data: {
              id: deletingItemsArray
            }
          });

          if (apiResponse?.data?.success) {
            showToast(apiResponse?.data?.message, 'success');
            setPagesList(prev => ({
              ...prev,
              data: prev?.data?.filter(
                page => !deletingItemsArray.includes(page?.id?.toString())
              )
            }));
          }
        } catch (error) {
          showToast(
            error?.response?.data?.message ||
              error?.message ||
              'Something went wrong, please refresh the page and try again.',
            'error'
          );
        } finally {
          setLoading(false);
          configConfirm({
            type: 'RESET'
          });
        }
      }
    };
    configConfirm({
      type: 'CONFIG',
      payload: configConfirmPayload
    });
  };

  const handleIncludePage = pagesArray => {
    if (typeof setPages !== 'function') return;
    setPages(prev => [
      ...(activePlace === 'top' ? pagesArray : []),
      ...(prev || []),
      ...(activePlace === 'bottom' ? pagesArray : [])
    ]);
  };

  const fetchPages = async (page, searchKey) => {
    try {
      apiRequestAbortControllerRef?.current?.abort();
      apiRequestAbortControllerRef.current = new AbortController();
      setSearchKey(searchKey);
      setFetching(true);
      const apiResult = await axios.get('proposals-media-files', {
        params: {
          page: page || 1,
          search: searchKey
        },
        signal: apiRequestAbortControllerRef.current?.signal
      });

      const apiResultData = apiResult?.data?.data;
      if (apiResult?.status === 200 && apiResult?.data?.data) {
        setPagesList(prev =>
          page > 1
            ? {
                ...apiResultData,
                data: [...(prev?.data || []), ...(apiResultData?.data || [])]
              }
            : apiResultData
        );
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setSearching(false);
      setFetching(false);
    }
  };

  useEffect(() => {
    // fetch data
    show && fetchPages(1);
    return () => {
      setFetching(false);
      setShowSelection(false);
      setShowSearch(false);
      setPagesList([]);
    };
  }, [show]);

  return show ? (
    <Modal
      className="proposal-page-selection-modal"
      show={show}
      size="lg"
      centered
    >
      <Modal.Header className="bg-light p-3 d-flex flex-wrap align-items-center gap-2">
        <p className="title text-dark fw-bold text-uppercase m-0">
          {Translate('Pages Library')}
        </p>

        <input
          type="file"
          ref={fileInputRef}
          accept="application/pdf"
          onChange={handleFiles}
          hidden
        />

        <Button
          size="sm"
          variant="transparent"
          className="file-upload-btn shadow-none rounded"
          type="button"
          onClick={handleFileInputClick}
        >
          <small>{Translate('Add New')}</small>
        </Button>

        {typeof onHide === 'function' ? (
          <Button
            size="sm"
            variant="transparent"
            className="ms-auto file-upload-btn shadow-none border rounded"
            type="button"
            onClick={event => onHide(event)}
          >
            <FaTimes />
          </Button>
        ) : null}
      </Modal.Header>
      <Modal.Body className="position-relative p-0">
        {fetching && nextPage <= 1 ? (
          <div className="form-layer-container p-5">
            <FormLayer />
          </div>
        ) : searchKey?.length > 0 || pagesListCount > 0 ? (
          <>
            <div className="sticky-top-section bg-white">
              <div className="p-3 d-flex flex-wrap justify-content-end gap-2 border-bottom bg-white options-button-container">
                <Button
                  variant={`${!showSelection ? 'outline-' : ''}secondary`}
                  className="border rounded d-flex flex-row align-items-center gap-1"
                  size="sm"
                  onClick={event => handleSwitchState(event, 'selection')}
                >
                  <FaRegSquareCheck size={12} />
                  <small>{Translate('Select')}</small>
                </Button>

                <Button
                  variant={`${!showSearch ? 'outline-' : ''}secondary`}
                  className="border rounded d-flex flex-row align-items-center gap-1"
                  size="sm"
                  onClick={handleSwitchState}
                >
                  <FaSearch size={12} />
                  <small>{Translate('Search')}</small>
                </Button>

                {selectedFiles?.length > 0 ? (
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="More"
                    size="sm"
                    className="rounded shadow-sm border-light"
                    variant="light"
                  >
                    <Dropdown.Item
                      className="d-flex flex-row align-items-center gap-2"
                      target-action="delete"
                      onClick={handleOptionsButton}
                    >
                      <FaTrash size={11} className="text-danger" />
                      <small>{Translate('Delete')}</small>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="d-flex flex-row align-items-center gap-2"
                      target-action="include"
                      onClick={handleOptionsButton}
                    >
                      <FaFileUpload size={11} className="text-success" />
                      <small>{Translate('Include')}</small>
                    </Dropdown.Item>
                  </DropdownButton>
                ) : null}
              </div>

              {showSearch ? (
                <div className="search-container drop-in">
                  <Search
                    placeholder={Translate('Search Files')}
                    onChange={() => !searching && setSearching(true)}
                    onSearch={searchKey => fetchPages(1, searchKey)}
                    autofocus
                  />
                </div>
              ) : null}
            </div>
            <ul className="list-unstyled m-0 d-flex flex-column">
              {searching ? (
                Array.from(Array(20).keys()).map(key => (
                  <li key={key} className="mx-3">
                    <Placeholder className="w-100" animation="glow" size="lg">
                      <Placeholder className="w-100 rounded searching-animation-item" />
                    </Placeholder>
                  </li>
                ))
              ) : pagesListCount > 0 ? (
                pagesList?.data?.map((page, index) => (
                  <li key={index} className="d-flex flex-row rounded">
                    <a
                      href="#"
                      role="button"
                      className="d-flex flex-row align-items-center gap-2 text-decoration-none px-3 mx-3 flex-fill"
                      onClick={event => handleLineItemClick(event, page)}
                    >
                      {showSelection ? (
                        page?.selected ? (
                          <FaRegSquareCheck className="text-success zoom-in" />
                        ) : (
                          <FaRegSquare className="text-secondary zoom-in" />
                        )
                      ) : null}
                      <FaFilePdf className="text-danger" />
                      <span className="text-dark">{page?.name}</span>
                      {!showSelection ? (
                        <Button
                          variant="transparent"
                          className="shadow-none p-0 ms-auto trash-btn"
                          target-action="singleDelete"
                          target-id={page?.id}
                          onClick={handleOptionsButton}
                        >
                          <FaTrash size={12} />
                        </Button>
                      ) : null}
                    </a>
                  </li>
                ))
              ) : (
                <Alert variant="info" className="mx-3 mb-0">
                  {Translate('No result found')}
                </Alert>
              )}
            </ul>

            {!fetching && !searching && pagesListCount > 0 ? (
              pagesList?.meta?.current_page >= lastPage ? (
                <p className="mt-5 text-center">
                  {Translate('You have seen it all')} &#x1F642;.
                </p>
              ) : (
                <DataFetching
                  nextLink={nextPage}
                  loadNextPage={() => fetchPages(nextPage, searchKey)}
                />
              )
            ) : null}

            {uploadingFileData ? (
              <div className="sticky-bottom-section bg-white">
                <ProgressBar
                  className="rounded-0"
                  variant={
                    uploadingFileData?.now >= 100 ? 'success' : 'primary'
                  }
                  striped
                  now={uploadingFileData?.now}
                  label={`${uploadingFileData?.now}%`}
                />
              </div>
            ) : null}
          </>
        ) : (
          <Alert variant="info" className="m-3">
            {Translate('No result found')}
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  ) : null;
}

ProposalPageSelectionModal.propTypes = {
  setPages: PropTypes.func,
  activePlace: PropTypes.oneOf(['top', 'bottom', undefined]),
  show: PropTypes.bool,
  onHide: PropTypes.func
};
