import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import SelectCostCenter from 'components/form/SelectCostCenter';
import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import Total from 'module/Common/Total';
import SelectSupplier from 'components/form/SelectSupplier';
import SelectPaymentTerms from 'components/form/SelectPaymentTerms';
import FileController from 'components/file-controller/FileController';
import PhoneNumberInput from 'components/form/PhoneNumberInput';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import PurchaseFormSaveButton from 'module/Purchase/common/components/PurchaseFormSaveButton';
import PurchaseFormCancelButton from 'module/Purchase/common/components/PurchaseFormCancelButton';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectWarehouse from 'components/form/SelectWarehouse';
import CustomFieldsSection from 'components/form/custom-fields-section/CustomFieldsSection';
import PropTypes from 'prop-types';
import TermsAndConditions from 'module/Common/InputFields/Sales/TermsAndConditions';
import TextEditor from 'module/Common/InputFields/TextEditor/TextEditor';
import FormLayer from 'module/Common/Layer/FormLayer';
import EntriesPopUpForm from 'components/entries-pop-up-form/EntriesPopUpForm';
import checkTrueCase from 'helpers/checkTrueCase';

const FixedAssetPurchaseInvoiceForm = ({
  editScreen,
  handleSubmit,
  formError,
  setFormError,
  formData,
  setFormData,
  handleFieldChange,
  handleAddTermsAndConditions,
  saveDraftData,
  orderId,
  deliveryId,
  customFieldItems,
  netAmounts,
  enableBatchSection,
  batchIsEditable,
  enableTaxGroup,
  onSave,
  isFetchingInfo,
  itemId
}) => {
  const Translate = useAxisproTranslate();
  const pendingcartItemFound = formData?.details?.find(
    item => item?.add_to_cart_ref === true && item?.trans_date
  );

  return (
    <Form onSubmit={handleSubmit} className="form-layer-container">
      {isFetchingInfo || pendingcartItemFound ? <FormLayer /> : null}
      <SimpleBar
        style={{
          height: '79vh',
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Card.Body className="pt-3">
          {Object.keys(formError).length > 0 && (
            <ErrorAlert formError={formError} setFormError={setFormError} />
          )}
          <Row>
            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="supplier_id">
                <Form.Label
                  column
                  xl={4}
                  md={4}
                  sm={12}
                  className="require-data"
                >
                  {Translate('Supplier Name')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    {orderId || deliveryId ? (
                      <Form.Control
                        readOnly
                        value={formData.supplier_name_ref}
                      />
                    ) : (
                      <SelectSupplier
                        value={formData.supplier_id_ref}
                        name="supplier_id"
                        handleFieldChange={handleFieldChange}
                        onFocus={saveDraftData}
                        placeholder={Translate('Search...')}
                        style={{ borderRadius: 'none' }}
                        includeDetailsKey
                        withAddButton
                      />
                    )}
                  </div>

                  <FormErrorPopover
                    id="supplier_id"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.supplier_id}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="supplier_id">
                <Form.Label
                  column
                  xl={4}
                  md={4}
                  sm={12}
                  className="require-data"
                >
                  {Translate('Supplier Reference')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    <Form.Control
                      name="supplier_reference"
                      onChange={handleFieldChange}
                      onFocus={saveDraftData}
                      value={formData.supplier_reference}
                    />
                  </div>

                  <FormErrorPopover
                    id="supplier_reference"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.supplier_reference}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="payment_term_id">
                <Form.Label column xl={4} md={4} sm={12}>
                  {Translate('Payment Term')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    {(orderId || deliveryId) &&
                    formData.payment_term_name_ref ? (
                      <Form.Control
                        readOnly
                        value={formData.payment_term_name_ref}
                      />
                    ) : (
                      <SelectPaymentTerms
                        value={formData.payment_term_id_ref}
                        name="payment_term_id"
                        handleFieldChange={handleFieldChange}
                        onFocus={saveDraftData}
                        placeholder={Translate('Search...')}
                        style={{ borderRadius: 'none' }}
                        includeThisKeys={['days']}
                        withAddButton
                      />
                    )}
                  </div>

                  <FormErrorPopover
                    id="payment_term_id"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.payment_term_id}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="trans_date">
                <Form.Label
                  column
                  xl={4}
                  md={4}
                  sm={12}
                  className="require-data"
                >
                  {Translate('Date')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    <AppDatePicker
                      name="trans_date"
                      value={formData.trans_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                      onFocus={saveDraftData}
                      showDefaultDate
                    />
                  </div>

                  <FormErrorPopover
                    id="trans_date"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.trans_date}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="due_date">
                <Form.Label column xl={4} md={4} sm={12}>
                  {Translate('Due Date')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    <AppDatePicker
                      name="due_date"
                      value={formData.due_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                      onFocus={saveDraftData}
                    />
                  </div>

                  <FormErrorPopover
                    id="due_date"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.due_date}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="cost_center_id">
                <Form.Label column xl={4} md={4} sm={12}>
                  {Translate('Cost Center')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    {(orderId || deliveryId) &&
                    formData.cost_center_name_ref ? (
                      <Form.Control
                        readOnly
                        value={formData.cost_center_name_ref}
                      />
                    ) : (
                      <SelectCostCenter
                        value={formData.cost_center_id_ref}
                        name="cost_center_id"
                        handleFieldChange={handleFieldChange}
                        onFocus={saveDraftData}
                        placeholder={Translate('Search...')}
                        style={{ borderRadius: 'none' }}
                        withAddButton
                      />
                    )}
                  </div>

                  <FormErrorPopover
                    id="cost_center_id"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.cost_center_id}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="warehouse_id">
                <Form.Label
                  column
                  xl={4}
                  md={4}
                  sm={12}
                  className="require-data"
                >
                  {Translate('Warehouse Name')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    {(orderId || deliveryId) && formData.warehouse_name_ref ? (
                      <Form.Control
                        readOnly
                        value={formData.warehouse_name_ref}
                      />
                    ) : (
                      <SelectWarehouse
                        value={formData.warehouse_id_ref}
                        name="warehouse_id"
                        handleFieldChange={handleFieldChange}
                        onFocus={saveDraftData}
                        placeholder={Translate('Search...')}
                        style={{ borderRadius: 'none' }}
                        withAddButton
                      />
                    )}
                  </div>

                  <FormErrorPopover
                    id="warehouse_id"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.warehouse_id}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="phone">
                <Form.Label column xl={4} md={4} sm={12}>
                  {Translate('Contact Number')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    <PhoneNumberInput
                      name="phone"
                      onChange={handleFieldChange}
                      onFocus={saveDraftData}
                      value={formData.phone}
                      readOnly={
                        (orderId || deliveryId) &&
                        formData.phone_ref?.length > 0
                      }
                    />
                  </div>

                  <FormErrorPopover
                    id="phone"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.phone}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="email">
                <Form.Label column xl={4} md={4} sm={12}>
                  {Translate('Contact Email')}
                </Form.Label>
                <Col
                  xl={8}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleFieldChange}
                      onFocus={saveDraftData}
                      value={formData.email}
                      readOnly={
                        (orderId || deliveryId) &&
                        formData.email_ref?.length > 0
                      }
                    />
                  </div>

                  <FormErrorPopover
                    id="email"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.email}
                    addSpace
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group as={Row} className="mb-3" controlId="tax_included">
                <Form.Label column xl={4} md={4} sm={12}>
                  {Translate('Tax Included')}
                </Form.Label>
                <Col
                  lg={5}
                  md={8}
                  sm={12}
                  className="d-flex flex-row align-items-center"
                >
                  <div className="flex-fill">
                    <Form.Check
                      type="switch"
                      checked={formData.tax_included}
                      name="tax_included"
                      onChange={handleFieldChange}
                      onFocus={saveDraftData}
                    />
                  </div>
                  <FormErrorPopover
                    id="tax_included"
                    totalErrorCount={Object.keys(formError).length}
                    errorMessage={formError.tax_included}
                  />
                </Col>
              </Form.Group>
            </Col>
            {customFieldItems ? (
              <CustomFieldsSection
                customFieldItems={customFieldItems}
                layout="grid"
                formData={formData}
                formError={formError}
                handleFieldChange={handleFieldChange}
                saveDraftData={saveDraftData}
              />
            ) : null}
          </Row>

          <EntriesPopUpForm
            type="FIXED-ASSET"
            transType="PI"
            transaction_date={formData?.trans_date}
            handleSaveDraftData={saveDraftData}
            taxIncluded={checkTrueCase(formData?.tax_included)}
            enableTaxGroup={enableTaxGroup}
            supplierDetails={formData?.supplierDetails_ref}
            addCaseTitle="Add Fixed Asset Entry"
            editCaseTitle="Edit Fixed Asset Entry Details"
            addToCart
            keyName="details"
            setFormData={setFormData}
            entries={formData?.details}
            enableBatchSection={enableBatchSection}
            batchIsEditable={batchIsEditable}
            {...(editScreen ? { transNumber: itemId } : null)}
          />

          <Row>
            <Col md={{ span: 6, order: 2 }}>
              <Total
                grossTotal={netAmounts.GrossTotal}
                subTotal={netAmounts.ItemTotal + netAmounts.GLTotal}
                netDiscount={netAmounts.discount}
                tax={netAmounts.tax}
              />
            </Col>
            <Col md={{ span: 6, order: 1 }}>
              <FileController
                limit={10}
                limitFeature={true}
                limitFileSizeInMB={5}
                limitFileType={[
                  'jpg',
                  'png',
                  'jpeg',
                  'gif',
                  'pdf',
                  'excel',
                  'xlsx',
                  'docx',
                  'doc'
                ]}
                supportHandleFieldChange
                onChange={handleFieldChange}
                className="my-3"
                note={Translate(
                  'You can upload a maximum of 10 files, 5MB each'
                )}
              />
            </Col>
          </Row>

          <TermsAndConditions
            name={'terms_and_conditions_option_ref'}
            value={formData.terms_and_conditions_option_ref}
            handleFieldChange={data =>
              handleFieldChange({
                target: {
                  value: data,
                  name: 'terms_and_conditions_option_ref'
                }
              })
            }
            addQuotationTerms={handleAddTermsAndConditions}
          />

          <Form.Group
            className="mb-3 mt-3"
            controlId="exampleForm.termsAndConditionsInput"
          >
            <div className="d-flex flex-row align-items-center">
              <div className="w-100">
                <TextEditor
                  name="terms_and_conditions"
                  handleFieldChange={handleFieldChange}
                  onFocus={saveDraftData}
                  value={formData.terms_and_conditions}
                  HideMedia={true}
                />
              </div>

              <FormErrorPopover
                id="exampleForm.dateInput"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.terms_and_conditions}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="memo">
            <Form.Label>{Translate('Notes')}</Form.Label>
            <div className="d-flex flex-row align-items-center">
              <div className="flex-fill">
                <Form.Control
                  type="text"
                  as="textarea"
                  name="memo"
                  rows={5}
                  onChange={handleFieldChange}
                  onFocus={saveDraftData}
                  value={formData.memo}
                />
              </div>
              <FormErrorPopover
                id="memo"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.memo}
              />
            </div>
          </Form.Group>
        </Card.Body>
      </SimpleBar>
      <Card.Footer className="footer d-flex flex-row gap-2 justify-content-start border-top">
        <PurchaseFormSaveButton
          onSave={onSave}
          {...(editScreen
            ? { text: 'Update', onSaveText: 'Updating...' }
            : null)}
        />
        <PurchaseFormCancelButton onSave={onSave} variant="danger" />
      </Card.Footer>
    </Form>
  );
};

FixedAssetPurchaseInvoiceForm.propTypes = {
  handleSubmit: PropTypes.func,
  formError: PropTypes.object,
  setFormError: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleAddTermsAndConditions: PropTypes.func,
  saveDraftData: PropTypes.func,
  orderId: PropTypes.string,
  deliveryId: PropTypes.string,
  customFieldItems: PropTypes.array,
  netAmounts: PropTypes.object,
  enableBatchSection: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  enableTaxGroup: PropTypes.bool,
  onSave: PropTypes.bool,
  itemId: PropTypes.string,
  editScreen: PropTypes.bool,
  isFetchingInfo: PropTypes.bool
};

export default FixedAssetPurchaseInvoiceForm;
